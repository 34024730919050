import albertaImg from "@images/provinces/alberta-card.jpg";
import bcImg from "@images/provinces/bc-card.jpg";
import manitobaImg from "@images/provinces/manitoba-card.jpg";
import nbImg from "@images/provinces/nb-card.jpg";
import nsImg from "@images/provinces/ns-card.jpg";
import ontarioImg from "@images/provinces/ontario-card.jpg";

export const CREDIT_COUNSELLORS = [
  {
    location: "Alberta",
    title: "Credit counselling society",
    website: "nomoredebt.com",
    phoneNumber: "1-888-527-8999",
    image: albertaImg,
    margin: "mt-0",
  },
  {
    location: "Alberta",
    title: "Credit counselling society",
    website: "nomoredebt.com",
    phoneNumber: "1-888-527-8999",
    image: albertaImg,
    className: "mt-[2.75rem]"
  },
  {
    location: "British Columbia",
    title: "Credit counselling society",
    website: "nomoredebt.com",
    phoneNumber: "1-888-527-8999",
    image: bcImg,
    className: "mt-[5.5rem] sm:mt-0"
  },
  {
    location: "Ontario",
    title: "Ontario association of credit counselling services",
    website: "oaccs.com",
    phoneNumber: "1-905-945-5644",
    image: ontarioImg,
    className: "mt-[8.25rem] md:mt-0 sm:mt-[2.75rem]"
  },
  {
    location: "Nova Scotia",
    title: "Credit counselling services of Atlantic Canada",
    website: "solveyourdebts.com",
    phoneNumber: "1-888-753-2227",
    image: nsImg,
    className: "mt-[11rem] md:mt-[2.75rem] sm:mt-0"
  },
  {
    location: "Saskatchewan",
    title: "Credit counselling society",
    website: "nomoredebt.com",
    phoneNumber: "1-888-527-8999",
    image: nbImg,
    className: "mt-[13.75rem] md:mt-[5.5rem] sm:mt-[2.75rem]"
  },
  {
    location: "Manitoba",
    title: "Credit counselling society",
    website: "nomoredebt.com",
    phoneNumber: "1-888-527-8999",
    image: manitobaImg,
    className: "mt-[16.5rem]"
  },
];
