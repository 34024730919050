import * as React from "react";

import { Box } from "@components/box";
import { PageLayout, SEO } from "@components/page-layout";
import { GrayPhrase, Paragraph, PageHeading } from "@components/typography";
import { CounsellorCard, DiagonalCardsContainer } from "@brainfinance/icash-component-library";
import { CREDIT_COUNSELLORS } from "@containers/credit-counsellors/credit-counsellor-data";

const CreditCounsellorsPage = () => (
  <PageLayout>
    <SEO title="Credit counsellors" />
    <Box className="text-center mt-[120px] lg:mt-[100px]">
      <PageHeading>Credit counsellors</PageHeading>
    </Box>
    <Paragraph className="max-w-[660px] mt-[22px] mb-[88px] mx-auto text-center">
      <GrayPhrase>Find a credit counsellor near you.</GrayPhrase>
    </Paragraph>
    <DiagonalCardsContainer className="mb-[5rem]">
      {CREDIT_COUNSELLORS.map((creditCounsellor, ndx) => (
        <CounsellorCard key={ndx} {...creditCounsellor} />
      ))}
    </DiagonalCardsContainer>
    <div className="h-[1px]" />
  </PageLayout>
);

export default CreditCounsellorsPage;
